import React, { useState } from "react";
import "./header.scss";
import { NavLink, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import ReactGA from "react-ga4";
const TRACKING_ID = "G-P7NZYHMXVN"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


const MainDivHeader = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(27px);
    @media only screen and (max-width: 1023px) {
        height: 60px;
    }
    ul {
      display: flex;
      list-style: none;

      li {
        cursor: pointer;
        text-decoration: none;
      }
    }
    button{
      font-style: normal;
      background: #ff8a00;
      border-radius: 46px;
      border: none;
      display: inline-block;
      font-weight: 600;
      transition: 0.5s;
      cursor: pointer;
      text-align: center;
      color: #ffffff;
      outline: none;
      padding: 10px 25px;
      font-size: 14px;
      width: 154px;
      height: 39px;
      @media only screen and (max-width: 567px) {
        font-size: 12px;
        height: 35px;
        width: 130px;
        padding: 6px 10px;
        border-radius: 32px;
        margin-top: 10px;
      }
    }
`;

const ContentHeaderWrapper = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  overflow: hidden;
  height: 100%;

`;

const HeaderMargin = styled.div`
  margin-left: 3.5vw;
  margin-right: 3.5vw;
`

const IpadMobNav = styled.div`
  @media only screen and (min-width: 1024px) {
    display:none
  }
  @media only screen and (max-width: 1023px) {
    display: block;
    img{
      position: absolute;
      width: 100px;
      top: 30%;
    }
  }
`

const DeskTopHeaderView = styled.div`
  @media only screen and (min-width: 1024px) {
    display: block;
    .header-dropdown {
      display: inline-block;
      p {
        padding: 0 15px;
        font-style: normal;
        font-weight: normal;
        line-height: 120%;
        color: #ffffff;
        font-size: 14px;
        text-decoration: none;
        cursor: pointer;
        @media only screen and (min-width: 768px) and (max-width: 1023px) {
          font-size: 12px;
        }
      }
    }
    .header-dropdown-content {
      display: none;
      position: absolute;
      z-index: 1;
      width: 100%;
      background-color: transparent;
      padding-top: 13px;
      padding-bottom: 15px;
      div {
        background: rgba(0, 0, 0, 0.8);
        padding-bottom: 20px;
        padding-top: 15px;
      }
    }
  
    .header-dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      font-style: normal;
      font-weight: normal;
      line-height: 120%;
      color: #ffffff;
      font-size: 14px;
      text-decoration: none;
      cursor: pointer;
      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 12px;
      }
    }
  
    .header-dropdown-content a:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  
    .header-dropdown:hover .header-dropdown-content {
      display: block;
    }
  }
  @media only screen and (max-width: 1023px) {
    display:none;
    overflow: hidden;  
  }
`


const MenuToggle = styled.div`
  @media only screen and (min-width: 1024px) {
    display: none;
    overflow: hidden;
  }
  @media only screen and (max-width: 1023px) {
    position: absolute;
    right: 0px;
    top: 30px;
    cursor: pointer;
  }
`
const LiPara = styled.p`
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  color: #ffffff;
  font-size: 14px;
`

const LiParaA = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  color: #ffffff;
  font-size: 14px;
`

const HeaderLinks = styled.div`
  p{
    padding: 0 15px;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    color: #ffffff;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
  
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      font-size: 12px;
    }
  }
`





const Header = () => {

  const [hamburgerMenu, sethamburgerMenu] = useState(true);
  const [hamburgerMenuClass, sethamburgerMenuClass] = useState("hamburger");
  const [sidenavOpenOverlayClass, setsidenavOpenOverlayClass] = useState(
    "setsidenavOpenOverlayNone"
  );

  const [showMenu, setShowMenu] = useState(true);

  const handleNaveOpen = (e) => {
    e.preventDefault();
    sethamburgerMenu(!hamburgerMenu);
    if (hamburgerMenu === true) {
      sethamburgerMenuClass("hamburger");
      setsidenavOpenOverlayClass("setsidenavOpenOverlayNone");
    } else if (hamburgerMenu === false) {
      sethamburgerMenuClass("hamburgerOpen");
      setsidenavOpenOverlayClass("setsidenavOpenOverlay");
    }
  };

  const getMobileOperatingSystem = () => {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
      window.location.href =
        "https://apps.apple.com/in/app/dhyana-meditation-tracker/id1471290028";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
      window.location.href =
        "https://apps.apple.com/in/app/dhyana-meditation-tracker/id1471290028";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
      window.location.href =
        "https://play.google.com/store/apps/details?id=org.avantari.dhyana&hl=en_IN";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
      window.location.href =
        "https://play.google.com/store/apps/details?id=org.avantari.dhyana&hl=en_IN";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
      window.location.href =
        "https://play.google.com/store/apps/details?id=org.avantari.dhyana&hl=en_IN";
    }
    return os;
  };

  let navigate = useNavigate();

  const clickPopup = () => {
    navigate("/order-now");
    sethamburgerMenuClass("hamburger");
    setsidenavOpenOverlayClass("setsidenavOpenOverlayNone");
  };

  return (
    <MainDivHeader>
      <ContentHeaderWrapper>
        <HeaderMargin>
          <IpadMobNav>
            <div>
              <div className={sidenavOpenOverlayClass}>
                <ul style={{ listStyle: `none` }}>
                  <li>
                    <div>
                      <NavLink
                        to="/contact-us"
                        style={{
                          cursor: `pointer`,
                          textDecoration: `none`,
                        }}
                      >
                        <LiPara>
                          Contact Us
                        </LiPara>
                      </NavLink>
                    </div>

                    <div style={{ marginTop: `22px` }}>
                      <LiParaA
                        href="http://smartdhyana.ubpages.com/a2bcb0fd-3cca-41d6-93e2-19b5fa7d9ca7/"
                      >
                        Elderly Care
                      </LiParaA>
                    </div>
                    <div style={{ marginTop: `22px` }}>
                      <LiParaA
                        href="https://smartdhyana.ubpages.com/a2bcb0fd-3cca-41d6-93e2-19b5fa7d9ca7-21/"
                      >
                        Mental Health
                      </LiParaA>
                    </div>
                    <div style={{ marginTop: `22px` }}>
                      <LiParaA
                        href="https://smartdhyana.ubpages.com/a2bcb0fd-3cca-41d6-93e2-19b5fa7d9ca7-22/"
                      >
                        Health & Wellness
                      </LiParaA>
                    </div>
                    <div style={{ marginTop: `22px` }}>
                      <LiPara onClick={getMobileOperatingSystem}>
                        Download App
                      </LiPara>
                    </div>
                    <button
                      onClick={clickPopup}>
                      Order now
                    </button>
                  </li>
                </ul>
              </div>
              <div>
                <NavLink to="/">
                  <img
                    src="https://d3mhjg7evfw0lx.cloudfront.net/dhyana2LogoHeader.svg"
                    onClick={() => {
                      sethamburgerMenuClass("hamburger");
                      setsidenavOpenOverlayClass("setsidenavOpenOverlayNone");
                    }}
                    alt="dhyana logo"
                  />
                </NavLink>
              </div>
              <div>
                {showMenu ? (
                  <MenuToggle

                    onClick={(e) => handleNaveOpen(e)}
                  >
                    <div className={hamburgerMenuClass}></div>
                  </MenuToggle>
                ) : (
                  <div style={{ float: `right` }}></div>
                )}
              </div>
            </div>
          </IpadMobNav>
        </HeaderMargin>
      </ContentHeaderWrapper>

      <DeskTopHeaderView>
        <header>
          <div className="header-logo" style={{ marginLeft: `3.5vw` }}>
            <NavLink to="/">
              <img
                src="https://d3mhjg7evfw0lx.cloudfront.net/dhyana2LogoHeader.svg"
                style={{ width: `100px` }}
                alt="dhyana logo"
              />
            </NavLink>
          </div>
          <HeaderLinks
            // className="header-links"
            style={{ marginRight: `3.5vw`, display: `flex` }}
          >
            <NavLink
              to="/contact-us"
              style={{
                cursor: `pointer`,
                textDecoration: `none`,
              }}
            >
              <p>
                Contact Us
              </p>
            </NavLink>
            <p onClick={getMobileOperatingSystem}>Download App</p>

            <div className="header-dropdown">
              <p>Solutions</p>
              <div className="header-dropdown-content">
                <div>
                  <a href="http://smartdhyana.ubpages.com/a2bcb0fd-3cca-41d6-93e2-19b5fa7d9ca7/">
                    Elderly Care
                  </a>
                  <a
                    href="https://smartdhyana.ubpages.com/a2bcb0fd-3cca-41d6-93e2-19b5fa7d9ca7-21/"
                    style={{ marginTop: `10px` }}
                  >
                    Mental Health
                  </a>
                  <a
                    href="https://smartdhyana.ubpages.com/a2bcb0fd-3cca-41d6-93e2-19b5fa7d9ca7-22/"
                    style={{ marginTop: `10px` }}
                  >
                    Health & Wellness
                  </a>
                </div>
              </div>
            </div>
            <button
              onClick={clickPopup}>
              Order now
            </button>
          </HeaderLinks>
        </header>
      </DeskTopHeaderView>
    </MainDivHeader>
  );
}

export default Header;
