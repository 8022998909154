import React, { useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import SubscriptionComponent from "../subscription-component";

const ParaFooter = styled.p`
  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 10px;
  line-height: 125%;
  margin: 0;
  text-decoration: none;
`;

const ColumnNew = styled.div`
  display: inline-block;
  padding: 10px;
  img {
    width: 16px;
    cursor: pointer;
  }
`;

const FooterMobile = () => {
  const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

  const [emailDetails, setemailDetails] = useState({
    email_address: "",

    formErrors: {
      email_address: "",
    },
  });

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/* <MarginDiv> */}
      <SubscriptionComponent />
      {/* </MarginDiv> */}
      <div style={{ marginTop: `10px` }}>
        <div style={{ textAlign: `center` }}>
          <ColumnNew>
            <a
              href="https://www.facebook.com/DhyanaRing/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://d3mhjg7evfw0lx.cloudfront.net/facebook.svg"
                alt="Forest"
                width="100%"
                height="100%"
              />
            </a>
          </ColumnNew>
          <ColumnNew>
            <a
              href="https://twitter.com/smartdhyana"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://d3mhjg7evfw0lx.cloudfront.net/twitter.svg"
                alt="Mountains"
                width="100%"
                height="100%"
              />
            </a>
          </ColumnNew>
          <ColumnNew>
            <a
              href="https://www.instagram.com/smartdhyana/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://d3mhjg7evfw0lx.cloudfront.net/instagram.svg"
                alt="Mountains"
                width="100%"
                height="100%"
              />
            </a>
          </ColumnNew>
          <ColumnNew>
            <a
              href="https://www.linkedin.com/showcase/smartdhyana/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://d3mhjg7evfw0lx.cloudfront.net/linkedIn.svg"
                alt="Mountains"
                width="100%"
                height="100%"
              />
            </a>
          </ColumnNew>
        </div>
        <div
          style={{
            marginTop: `30px`,
            display: `flex`,
            justifyContent: `center`,
          }}
        >
          <NavLink
            to="/wellness-glossary"
            style={{
              cursor: `pointer`,
              textDecoration: `none`,
              marginRight: `5px`,
            }}
            onClick={scrollToTop}
          >
            <ParaFooter>Wellness Glossary</ParaFooter>
          </NavLink>
          |
          <NavLink
            to="/terms-and-conditions"
            style={{
              cursor: `pointer`,
              textDecoration: `none`,
              marginLeft: `5px`,
              marginRight: `5px`,
            }}
            onClick={scrollToTop}
          >
            <ParaFooter>Terms and Conditions</ParaFooter>
          </NavLink>
          |
          <NavLink
            to="/privacy-policy"
            style={{
              cursor: `pointer`,
              textDecoration: `none`,
              marginLeft: `10px`,
            }}
            onClick={scrollToTop}
          >
            <ParaFooter>Privacy Policy</ParaFooter>
          </NavLink>
        </div>
        <ParaFooter style={{ opacity: `0.3`, textAlign: `center` }}>
          <span style={{ fontWeight: `600` }}>
            dhyana<sup>TM</sup>
          </span>{" "}
          is a product of
          <a style={{
              textDecoration: `none`,
              color: `#000000`,
              marginLeft: `3px`,
            }}
            href="https://www.avantari.org/"
            target="_blank"
          >
            Avantari Technologies ©2023
          </a>
        </ParaFooter>
      </div>
    </>
  );
};

export default FooterMobile;
