import React, { useState } from "react";
import "../styles/style.scss";
import styled from "styled-components";
import { makeStyles, Button, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  textField: {
    padding: 0,
  },

  root: {
    "& .MuiInputLabel-animated": {
      color: "red", // or black
    },
  },

  input: {
    fontFamily: "'Montserrat', sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#000000",
    fontSize: "14px",
    height: "25px",
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  root_checked: {
    "&$checked": {
      color: "black",
      position: "relative",
      bottom: "10px",
    },
    "&:hover": {
      position: "relative",
      bottom: "10px",
      background: "transparent",
    },
    "&:unchecked": {
      position: "relative",
      bottom: "10px",
    },
  },

  MuiCheckbox_root: {
    position: "relative",
    bottom: "10px",
  },
  checked: {
    position: "relative",
    bottom: "10px",
  },
  btn: {
    "&:hover": {
      background: "black",
    },
  },

  noborder: {
    border: "none",
  },

  place_order_btn: {
    fontFamily: "'Montserrat', sans-serif",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "700",
    width: "174px",
    height: "39px",
    textTransform: "none",
    background: "black",
    color: "white",
    textDecoration: "none",
    borderRadius: "25px",
    padding: "10px 20px",
    position: `relative`,
    top: `10px`,
    "@media (max-width: 567px)": {
      width: "100%",
      marginTop: `20px`,
      fontSize: `14px`,
      height: `35px`,
    },
  },
}));

const schema = yup.object().shape({});

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 60% auto;
  @media only screen and (max-width: 567px) {
    grid-template-columns: repeat(1, 1fr);
  }
  column-gap: 20px;
`;

const PageContainer1 = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  @media only screen and (max-width: 567px) {
    grid-template-columns: repeat(1, 1fr);
  }
  column-gap: 60px;
`;

const Greet = styled.div`
  // border: 1px solid;
`;

const Greet1 = styled.div`
  position: relative;
  div {
    position: absolute;
    right: 0px;
  }
  @media only screen and (max-width: 567px) {
    display: none;
  }
  @media only screen and (min-width: 568px) {
    display: block;
  }
`;

const Greet2 = styled.div`
  position: relative;
  @media only screen and (max-width: 567px) {
    display: none;
  }
  @media only screen and (min-width: 568px) {
    display: block;
  }
`;

const Greet3 = styled.div`
  @media only screen and (max-width: 567px) {
    display: block;
  }
  @media only screen and (min-width: 568px) {
    display: none;
  }
`;

const MarginDiv = styled.div`
  margin-left: 3.5vw;
  margin-right: 3.5vw;
  margin-top: 40px;
  @media only screen and (min-width: 568px) {
    margin-bottom: 100px;
  }
`;

const SubscribeTitle = styled.p`
  
  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 36px;
  line-height: 43.2px;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 60px;
  @media only screen and (min-width: 568px) and (max-width: 1023px) {
    font-size: 28px;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  @media only screen and (max-width: 567px) {
    font-size: 24px;
    line-height: 28px;
    padding-top: 20px;
    padding-bottom: 40px;
  }
`;

const SubscriptionComponent = () => {
  const classes = useStyles();

  const [emailAddNewsletter, setemailAddNewsletter] = useState("");
  const [emailAddNewsletterError, setemailAddNewsletterError] = useState("");
  const [errorColor, seterrorColor] = useState("");
  const [disabledButton, setdisabledButton] = useState(false);
  const [isloadingSub, setisloadingSub] = useState(true);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const validateEmail = (mail) => {
    let emailError = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(mail);
    if (emailError === true) {
      setemailAddNewsletterError(false);
      setdisabledButton(true);
    } else {
      setemailAddNewsletterError("Valid email address is required");
      seterrorColor("red");
      setdisabledButton(false);
    }
  };

  const handleReset = () => {
    setemailAddNewsletter("");
  };

  const onSubmit = async (event) => {
    setisloadingSub(false);
    if (emailAddNewsletter.trim().length === 0) {
      setemailAddNewsletterError("Email address is required");
      seterrorColor("red");
    } else {
      let newsletterData = {
        email: emailAddNewsletter,
      };

      console.log(newsletterData);

      try {
        let res = await axios({
          method: "post",
          url: "https://api.smartdhyana.com/v1/newslettermail",
          data: newsletterData,
        });
        console.log(res);

        let data = res.data;
        if (res.status === 200) {
          setemailAddNewsletterError("Successfully subsrcibed!");
          seterrorColor("green");
          setisloadingSub(true);
          setdisabledButton(false);
          handleReset();
        }
        return data;
      } catch (error) {
        if (error.response.status === 400) {
          setemailAddNewsletterError("You have already subscribed");
          seterrorColor("black");
          setisloadingSub(true);
          setdisabledButton(false);
          handleReset();
          return error.response;
        } else {
          setisloadingSub(true);
          setdisabledButton(false);
          handleReset();
        }
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <MarginDiv>
        <PageContainer>
          <Greet>
            <img
              src="https://d3mhjg7evfw0lx.cloudfront.net/blog/dhyana-logo-black.svg"
              style={{ margin: `0`, width: `80px` }}
              alt="dhyana blog"
            />
            <SubscribeTitle>
              Subscribe to our newsletter. Get insights on mindful living
              delivered straight to your inbox.
            </SubscribeTitle>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <>
                  <PageContainer>
                    <div>
                      <TextField
                        name="email"
                        fullWidth
                        id="standard-basic"
                        label="e-mail"
                        variant="standard"
                        inputProps={{
                          className: classes.input,
                          className: classes.root,
                          style: {
                            fontFamily: `"Montserrat", sans-serif`,
                            fontStyle: `normal`,
                            fontWeight: `normal`,
                            fontSize: `14px`,
                            color: `#000000`,
                          },
                        }}
                        type="text"
                        value={emailAddNewsletter}
                        onChange={(e) => {
                          setemailAddNewsletter(e.target.value);
                          setemailAddNewsletterError("");
                          validateEmail(e.target.value);
                          if (
                            errorColor === "green" ||
                            errorColor === "black"
                          ) {
                            e.target.value = "";
                          }
                        }}
                      />
                      {emailAddNewsletterError ? (
                        <span
                          className="paraSpanContactUsPopUp"
                          style={{
                            color: errorColor,
                            fontFamily: `"Montserrat", sans-serif;`,
                          }}
                        >
                          {emailAddNewsletterError}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div>
                      {isloadingSub ? (
                        <>
                          {" "}
                          {disabledButton ? (
                            <Button
                              type="submit"
                              className={`${classes.place_order_btn} ${classes.btn}`}
                            >
                              Subscribe
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              className={`${classes.place_order_btn} ${classes.btn}`}
                              style={{
                                cursor: `default`,
                                color: `#ffffff`,
                              }}
                              disabled
                            >
                              Subscribe
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <Button
                            type="submit"
                            className={`${classes.place_order_btn} ${classes.btn}`}
                            style={{
                              cursor: `default`,
                              color: `#ffffff`,
                            }}
                            disabled
                          >
                            Loading ...
                          </Button>
                        </>
                      )}
                      <div></div>
                    </div>
                  </PageContainer>
                </>
              </form>
            </div>
          </Greet>
          <Greet>
            <PageContainer1>
              <Greet1>
                <div>
                  <p className="menu1">Menu</p>
                  <NavLink
                    to="/blog"
                    style={{
                      textDecoration: `none`,
                    }}
                    onClick={scrollToTop}
                  >
                    <p className="menu2">Blogs</p>
                  </NavLink>
                  <NavLink
                    to="/faq"
                    style={{
                      textDecoration: `none`,
                    }}
                    onClick={scrollToTop}
                  >
                    <p className="menu2">FAQs</p>
                  </NavLink>
                  <NavLink
                    to="/intellectual-property"
                    style={{
                      textDecoration: `none`,
                    }}
                    onClick={scrollToTop}
                  >
                    <p className="menu2">Intellectual Property</p>
                  </NavLink>
                  {" "}
                </div>
              </Greet1>
              <Greet2>
                <p className="menu1"> Shop</p>
                <p>
                  <a
                    className="menu2"
                    style={{ position: `relative`, bottom: `4px` }}
                    href="https://apps.apple.com/in/app/dhyana-meditation-tracker/id1471290028"
                  >
                    AppStore
                  </a>
                </p>
                <p>
                  <a
                    className="menu2"
                    style={{ position: `relative`, bottom: `5px` }}
                    href="https://play.google.com/store/apps/details?id=org.avantari.dhyana&hl=en_IN"
                  >
                    Playstore
                  </a>
                </p>
              </Greet2>
              <Greet3>
                <div
                  style={{
                    marginTop: `30px`,
                    display: `flex`,
                    justifyContent: `center`,
                  }}
                >
                  <div style={{ textAlign: `center` }}>
                    <NavLink
                      to="/blog"
                      style={{
                        textDecoration: `none`,
                      }}
                      className="menu2"
                      onClick={scrollToTop}
                    >
                      Blogs
                    </NavLink>
                  </div>
                  <div style={{ textAlign: `center` }}>
                    <NavLink
                      to="/faq"
                      style={{
                        textDecoration: `none`,
                      }}
                      className="menu2"
                      onClick={scrollToTop}
                    >
                      FAQs
                    </NavLink>
                  </div>
                  <div style={{ textAlign: `center` }}>
                    <NavLink
                      to="/intellectual-property"
                      style={{
                        textDecoration: `none`,
                      }}
                      className="menu2"
                      onClick={scrollToTop}
                    >
                      Intellectual Property
                    </NavLink>
                  </div>
                  <div style={{ textAlign: `center` }}>
                    <a
                      className="menu2"

                      href="https://apps.apple.com/in/app/dhyana-meditation-tracker/id1471290028"
                    >
                      AppStore
                    </a>
                  </div>
                  <div style={{ textAlign: `center` }}>
                    <a
                      className="menu2"

                      href="https://play.google.com/store/apps/details?id=org.avantari.dhyana&hl=en_IN"
                    >
                      Playstore
                    </a>
                  </div>

                </div>
              </Greet3>
            </PageContainer1>
          </Greet>
        </PageContainer>
      </MarginDiv>
    </>
  );
};

export default SubscriptionComponent;
