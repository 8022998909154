import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import SubscriptionComponent from "../subscription-component";


const PageContainer1 = styled.div`
  display: grid;
  grid-template-columns: 30% auto 30%;
  margin-top: 10px;
`;

const GreetFooter = styled.div``;

const ParaFooter = styled.p`
  font-style: normal;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 11px;
  line-height: 125%;
  margin: 0;
  position: relative;
  top: 12px;
  text-decoration: none;
  @media only screen and (max-width: 1023px) {
    font-size: 9px;
    top: 11px;
  }
`;

const ColumnNew = styled.div`
  display: inline-block;
  padding: 10px;
  img {
    width: 16px;
    cursor: pointer;
  }
`;

const MarginDiv = styled.div`
  margin-left: 3.5vw;
  margin-right: 3.5vw;
  margin-top: 40px;
  margin-bottom: 100px;
`;

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      {/* <MarginDiv> */}
      <SubscriptionComponent />
      {/* </MarginDiv> */}
      <PageContainer1>
        <GreetFooter>
          <ParaFooter>
            <span style={{ fontWeight: `600` }}>dhyana™</span> is a product of
            <a
              style={{
                textDecoration: `none`,
                color: `#000000`,
                marginLeft: `3px`,
              }}
              href="https://www.avantari.org/"
              target="_blank"
            >
              Avantari Technologies ©2023
            </a>
          </ParaFooter>
        </GreetFooter>
        <GreetFooter>
          <div
            style={{
              textAlign: `center`,
            }}
          >
            <ColumnNew>
              <a
                href="https://www.facebook.com/DhyanaRing/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://d3mhjg7evfw0lx.cloudfront.net/facebook.svg"
                  alt="Forest"
                  width="100%"
                  height="100%"
                />
              </a>
            </ColumnNew>
            <ColumnNew>
              <a
                href="https://twitter.com/smartdhyana"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://d3mhjg7evfw0lx.cloudfront.net/twitter.svg"
                  alt="Mountains"
                  width="100%"
                  height="100%"
                />
              </a>
            </ColumnNew>
            <ColumnNew>
              <a
                href="https://www.instagram.com/smartdhyana/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://d3mhjg7evfw0lx.cloudfront.net/instagram.svg"
                  alt="Mountains"
                  width="100%"
                  height="100%"
                />
              </a>
            </ColumnNew>
            <ColumnNew>
              <a
                href="https://www.linkedin.com/showcase/smartdhyana/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://d3mhjg7evfw0lx.cloudfront.net/linkedIn.svg"
                  alt="Mountains"
                  width="100%"
                  height="100%"
                />
              </a>
            </ColumnNew>
          </div>
        </GreetFooter>
        <GreetFooter>
          <div style={{ display: `flex`, float: `right` }}>
            <NavLink
              to="/wellness-glossary"
              style={{
                cursor: `pointer`,
                textDecoration: `none`,
              }}
              onClick={scrollToTop}
            >
              <ParaFooter>Wellness Glossary</ParaFooter>
            </NavLink>
            <NavLink
              to="/terms-and-conditions"
              style={{
                cursor: `pointer`,
                textDecoration: `none`,
                marginLeft: `30px`,
              }}
              onClick={scrollToTop}
            >
              <ParaFooter>Terms and Conditions</ParaFooter>
            </NavLink>
            <NavLink
              to="/privacy-policy"
              style={{
                cursor: `pointer`,
                textDecoration: `none`,
                marginLeft: `30px`,
              }}
              onClick={scrollToTop}
            >
              <ParaFooter>Privacy Policy</ParaFooter>
            </NavLink>
          </div>
        </GreetFooter>
      </PageContainer1>
    </>
  );
};

export default Footer;
