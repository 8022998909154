import React, { useState } from "react";
import "./header.scss";
import "./banner.scss";
import { useLocation, useNavigate } from "react-router-dom";
// import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ReactGA from "react-ga4";
const TRACKING_ID = "G-P7NZYHMXVN"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


const MainDivHeader = styled.div`
    margin-top:70px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    @media only screen and (max-width: 1023px) {
        margin-top:60px;
        height: 60px;
    }
`;

const ContentHeaderWrapper = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  overflow: hidden;
`;

const Banner = () => {

  const location = useLocation();
  const navigate = useNavigate();

  // List of paths where the banner should be hidden
  const hiddenPaths = ['/order-now', '/checkout'];

  // Check if the current path is in the hiddenPaths list
  if (hiddenPaths.includes(location.pathname)) {
    return null
  }


  const handleOrderNow = () => {
    const code = "YOGADAY24";
    navigator.clipboard.writeText(code).then(() => {
      navigate('/order-now'); // Navigate to the desired route
    });
  }

  return (
    <MainDivHeader>
      <ContentHeaderWrapper>
        <div className="banner">
          <div style={{ display: `flex` }}>
            <img src="https://d3mhjg7evfw0lx.cloudfront.net/heroimages/Discount.png" />
            <div>
              <span className="Bannercontent">YOGA DAY OFFER: <strong>FLAT 24% OFF!</strong> Use coupon code <strong>YOGADAY24</strong> at checkout! Hurry, limited stocks only!</span>            </div>
          </div>
          <div style={{ textAlign: `right` }}>
            <button className="offer-button" onClick={handleOrderNow}>Copy coupon code</button>
          </div>
        </div>
      </ContentHeaderWrapper>
    </MainDivHeader>
  );
}

export default Banner;
