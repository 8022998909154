import React from "react";
import "./style.scss";
import "../../styles/homestyled.scss";
import { useNavigate } from "react-router-dom";
import Footer from "../../footer";
import FooterMobile from "../../footer-mobile";

const servicesData13 = [
  {
    id: 0,
    img: "https://d3mhjg7evfw0lx.cloudfront.net/thirteenImage1.svg",
    size: "XS",
    title: "Fits ring sizes 5-7",
  },
  {
    id: 1,
    img: "https://d3mhjg7evfw0lx.cloudfront.net/thirteenImage2.svg",
    size: "S",
    title: "Fits ring sizes 7-9",
  },
  {
    id: 2,
    img: "https://d3mhjg7evfw0lx.cloudfront.net/thirteenImage3.svg",
    size: "M",
    title: "Fits ring sizes 9-11",
  },
  {
    id: 3,
    img: "https://d3mhjg7evfw0lx.cloudfront.net/thirteenImage4.svg",
    size: "L",
    title: "Fits ring sizes 11-13",
  },
  {
    id: 4,
    img: "https://d3mhjg7evfw0lx.cloudfront.net/thirteenImage5.svg",
    size: "XL",
    title: "Fits ring sizes 13-15",
  },
];

function SizingPageComp({ }) {
  let navigate = useNavigate();

  const handleClickPrompt = () => {
    navigate("/order-now");
  };
  return (
    <div style={{ marginTop: `100px` }}>
      <div className="popupDivOrder">
        <div id="popup1" className="overlay">
          <div className="popup" id="popuplength">
            <div className="PopUpDivSizing">
              <p className="ParaGo" style={{ textDecoration: `underline` }} onClick={handleClickPrompt}>
                Go back
              </p>
              <div>
                <p className="Para1SizingPoopup">dhyana size guide</p>
                <p
                  className="Para2SizingPoopup"
                  style={{ fontWeight: `normal` }}
                >
                  dhyana’s unique design allows it to expand with your finger,
                  plus there are 5 size options so you can find your fit. We
                  have an easy to use scale system to measure your ring size
                  online{" "}
                </p>
                <div>
                  <div id="container">
                    {servicesData13.map((servicesData13, index) => (
                      <div class="itemS1New">
                        <img
                          src={servicesData13.img}
                          width=""
                          height=""
                          alt={servicesData13.atltext}
                          className="classimg"
                          style={{ width: `85%` }}
                        />

                        <div className="newDivssNew">
                          <p className="caption" style={{ fontWeight: `700` }}>
                            {servicesData13.size}
                          </p>
                          <p className="caption">{servicesData13.title}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <p
                  className="Para2SizingPoopup"
                  style={{ fontWeight: `600`, paddingBottom: `10px` }}
                >
                  How does the dhyana free sizing kit work?
                </p>
                <p
                  className="Para2SizingPoopup"
                  style={{ fontWeight: `normal` }}
                >
                  Once you place your order, the free sizing kit will be
                  delivered to you first. Use the sample rings in the kit to
                  find a comfortable fit on your preferred finger. We recommend
                  wearing the sample ring for atleast 24hrs to find a
                  comfortable fit. Once you find your fit, confirm your size by
                  visiting the link on the sizing kit packaging and we will ship
                  your dhyana ring to you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="sizingGuideResponsive1">
    //   <div className="contentWrapper">
    //     <div className="MarginDivSizingPopup">
    //       <div className="MarginTopBottomFirst1Sizingpage">
    //         <p className="ParaGo" onClick={handleClickPrompt}>
    //           Go back
    //         </p>
    //         <div id="popup1" className="">
    //           <div className="popup">
    //             <div style={{ marginTop: `25px` }}>
    //               <p className="Para1SizingPagee">dhyana size guide</p>
    //               <p
    //                 className="Para2SizingPagee"
    //                 style={{ fontWeight: `normal` }}
    //               >
    //                 dhyana’s unique design allows it to expand with your finger,
    //                 plus there are 5 size options so you can find your fit. We
    //                 have an easy to use scale system to measure your ring size
    //                 online{" "}
    //               </p>
    //               <div>
    //                 <div id="container">
    //                   {servicesData13.map((servicesData13, index) => (
    //                     <div class="itemS1New">
    //                       <img
    //                         src={servicesData13.img}
    //                         width=""
    //                         height=""
    //                         alt={servicesData13.title}
    //                         className="classimg"
    //                         style={{ width: `85%` }}
    //                       />

    //                       <div className="newDivssNew">
    //                         <p
    //                           className="caption"
    //                           style={{ fontWeight: `700` }}
    //                         >
    //                           {servicesData13.size}
    //                         </p>
    //                         <p className="caption">{servicesData13.title}</p>
    //                       </div>
    //                     </div>
    //                   ))}
    //                 </div>
    //               </div>
    //               <p
    //                 className="Para2SizingPagee"
    //                 style={{ fontWeight: `600`, paddingBottom: `10px` }}
    //               >
    //                 How does the dhyana free sizing kit work?
    //               </p>
    //               <p
    //                 className="Para2SizingPagee"
    //                 style={{ fontWeight: `normal` }}
    //               >
    //                 Once you place your order, the free sizing kit will be
    //                 delivered to you first. Use the sample rings in the kit to
    //                 find a comfortable fit on your preferred finger. We
    //                 recommend wearing the sample ring for atleast 24hrs to find
    //                 a comfortable fit. Once you find your fit, confirm your size
    //                 by visiting the link on the sizing kit packaging and we will
    //                 ship your dhyana ring to you.
    //               </p>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div style={{ marginTop: `100px` }}>
    //     <div style={{ background: `#e1e1e1` }}>
    //       <div className="contentWrapper" style={{ padding: `25px` }}>
    //           <div className="deskTopView">
    //             <Footer />
    //           </div>
    //           <div className="mobView">
    //             <FooterMobile />
    //           </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default SizingPageComp;
