import React, { Suspense, useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header/index";
import loaderData from "./container/homepage/loader.json";
import Lottie from "react-lottie";
import SizingPageComp from "./components/new-buy-now/sizing-page-comp";
import axios from "axios";
import { countries } from "country-data";

import ReactGA from "react-ga4";
import Banner from "./components/banner";
const TRACKING_ID = "G-P7NZYHMXVN"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const PageNotFound = React.lazy(() => import("./components/page-not-found"));
const HomePage = React.lazy(() => import("./container/homepage"));
const NewBuyNow = React.lazy(() => import("./components/new-buy-now/index"));
const CheckoutPage = React.lazy(() =>
  import("./components/new-buy-now/checkout-page")
);
const ShippingComp = React.lazy(() =>
  import("./components/new-buy-now/shipping-comp/index")
);
const BlogPage = React.lazy(() => import("./components/blog-page"));
const SelectedBlogDhyana = React.lazy(() =>
  import("./components/blog-page/selected-blog-dhyana")
);
const PrivacyPolicy = React.lazy(() =>
  import("./components/privacy-policy/privacy-policy")
);
const TermsAndConditions = React.lazy(() =>
  import("./components/terms-and-conditions/terms-and-conditions")
);
const PaymentSucces = React.lazy(() => import("./components/paymentSuccess"));
const PaymentFail = React.lazy(() => import("./components/paymentFail"));
const PaymentSuccesInstamojo = React.lazy(() =>
  import("./components/PaymentSuccesInstamojo")
);
const RingSizeConfirm = React.lazy(() =>
  import("./components/ring-size-confirmation/index")
);
const RingFitEnterOrder = React.lazy(() =>
  import("./components/ring-size-confirmation/ring-enter-order")
);
const RingFitComp = React.lazy(() =>
  import("./components/ring-size-confirmation/ring-fit-comp")
);
const RingSizeFitSuccess = React.lazy(() =>
  import("./components/ring-size-confirmation/ring-size-fit-success")
);
const WebsiteGlossary = React.lazy(() =>
  import("./components/website-glossary/index")
);
const WebsiteSelectedGlossary = React.lazy(() =>
  import("./components/website-selected-glossary/index")
);
const AwsUpload = React.lazy(() =>
  import("./components/aws-upload/index")
);

const DashboardDhyana = React.lazy(() =>
  import("./components/dashboard-dhyana/index")
);

const FAQpage = React.lazy(() =>
  import("./components/faq-page/index")
);

const PatentComp = React.lazy(() =>
  import("./components/patent/index")
);

const ContactUs = React.lazy(() =>
  import("./components/contact-us")
);

const EBrochure = React.lazy(() =>
  import("./components/e-brochure")
);

const override = `
  display: block;
  margin: 0 auto;
`;

function App() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


  const [showPopup, setshowPopup] = useState(false);

  const [savedcart] = useState(localStorage.getItem("cart-items"));
  const [cart, setCart] = useState(savedcart ? JSON.parse(savedcart) : []);

  const [countryName, setCountryName] = useState("");
  const [countryCode, setCountryCode] = useState("");


  const [amount, setAmount] = useState({});

  // const [countryDetails, setCountryDetails] = useState(null);
  // const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Get user's IP address using a third-party service (ipstack in this example)
      try {
        // Get user's IP address using ipinfo.io API
        const ipinfoApiKey = '145512c7e2a612';
        const ipinfoResponse = await axios.get(`https://ipinfo.io/json?token=${ipinfoApiKey}`);
        console.log(countries[ipinfoResponse.data.country].name)
        console.log(ipinfoResponse.data.country)

        // console.log(response.data.country_name,response.data, "first")
        localStorage.setItem("country_name", JSON.stringify(ipinfoResponse.data.country));
        setCountryName(countries[ipinfoResponse.data.country].name)
        localStorage.setItem("country_code", JSON.stringify(ipinfoResponse.data.country));
        setCountryCode(ipinfoResponse.data.country)

      } catch (error) {
        // setError(error);
        // setCountryDetails({ country: 'DefaultCountry' });

        localStorage.setItem("country_name", JSON.stringify("United States"));
        setCountryName("United States")
        localStorage.setItem("country_code", JSON.stringify("US"));
        setCountryCode("US")
        console.log({ country: 'DefaultCountry' })
      }
    };

    fetchData();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.pathname,
    });
  }, []);


  return (
    <>
      <Router>
        <Header
          cart={cart}
          setCart={setCart}
          showPopup={showPopup}
          setshowPopup={setshowPopup}
        />
        <Banner />
        <Suspense
          fallback={
            <div>
              <Lottie
                options={defaultOptions}
                height={400}
                width={400}
                css={override}
              />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/blog" element={<BlogPage />} />

            <Route path="/blog/:id" element={<SelectedBlogDhyana />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/wellness-glossary" element={<WebsiteGlossary />} />
            <Route path="/wellness-glossary/:id" element={<WebsiteSelectedGlossary />} />
            <Route path="/intellectual-property" element={<PatentComp />} />
            <Route path="/ringfit" element={<RingSizeConfirm />}>
              <Route path="/ringfit" element={<RingFitEnterOrder />} />
              <Route path=":id" element={<RingFitComp />} />
            </Route>
            <Route
              path="/ringfit/ringfitSuccess"
              element={<RingSizeFitSuccess />}
            />
            <Route
              path="/order-now"
              element={
                <NewBuyNow
                  amount={amount}
                  setAmount={setAmount}
                  countryName={countryName}
                  setCountryName={setCountryName}
                  countryCode={countryCode}
                  setCountryCode={setCountryCode}
                />
              }
            />
            <Route
              path="/checkout"
              element={
                <CheckoutPage
                  amount={amount}
                  setAmount={setAmount}
                  countryName={countryName}
                  setCountryName={setCountryName}
                  countryCode={countryCode}
                  setCountryCode={setCountryCode}
                />
              }
            />
            <Route path="/shippingterms" element={<ShippingComp />} />
            <Route path="/sizing-guide" element={<SizingPageComp />} />
            <Route path="/paymentsuccess" element={<PaymentSucces />} />
            <Route path="/paymentfail" element={<PaymentFail />} />
            <Route path="/paymentstatus" element={<PaymentSuccesInstamojo />} />
            <Route path="/FAQ" element={<FAQpage />} />
            <Route path="/aws-upload" element={<AwsUpload />} />
            <Route path="/dashboard" element={<DashboardDhyana />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/e-brochure" element={<EBrochure />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />

            {/* {useRoutes.map((route) => {
            const { path, Component, children = [] } = route;
            return (
              <Route key={path} path={path} element={<Component />}>
                {children.map((route) => {
                  const { path, Component } = route;
                  return (
                    <Route key={path} path={path} element={<Component />} />
                  );
                })}
              </Route>
            );
          })} */}
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
